import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import EVCButton from '../../../components/EVCButton'
import DeleteAccountConstants from '../../../constants/DeleteAccountConstants';

export default function DeleteAccountForm({onSubmit, deletingAccount}) {
const { register, handleSubmit, formState: { errors } } = useForm();
const [email, setEmail] = useState('')

  /// Send the form data to the parent component
  async function onSubmitForm(formData){
    if(deletingAccount) return;
    
    let workedFormData= {
      email: formData.email,
    };
    
    onSubmit(workedFormData)
  }

  // Sets the new value of the email typed
  const onChange = (e) => {
    setEmail(e.target.value)
  }
  return (
    <form onSubmit={handleSubmit(onSubmitForm)}className='w-full'>
    <div className='flex flex-col items-start justify-start h-auto ml-8'>
      <div className={`h-4`}/>
        <input  className={`w-5/6 desktop:w-3/6 h-10 border text-sm placeholder-black rounded-full p-2.5`} 
        {...register('email', {
          required: true,
          pattern: {
            value: /[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9-.+]+\.[a-zA-Z]+/,
            message: 'Please write a valid email'
          }})} 
          value={email} 
          onChange={onChange} 
          type="text" id="waitlist_email"  placeholder={DeleteAccountConstants.deleteFormPageEmailPlaceholder} ></input>
        <div className='h-8'/>
    <div className={`h-4`}/>
    </div>
    {errors.email?.message ? <p className='mt-2 ml-8 text-sm text-red-600 -translate-y-12 desktop:-translate-y-12 desktop:mt-4 desktop:ml-12'>{errors.email.message}</p> : null }
    <EVCButton buttonText={DeleteAccountConstants.deleteFormPageDeleteButtonText} buttonColor={'bg-evc-light-green'} buttonTextColor={'white'} onClick={()=> {}} loading={deletingAccount} />
    </form>
  )
}
