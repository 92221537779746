
import { ToastContainer } from 'react-toastify';
import DeleteFormPage from './pages/DeleteFormPage/DeleteFormPage';


function App() {
  return (
    <>
      <div className="font-evcFont">
      <ToastContainer position="top-center"/>
      <DeleteFormPage/>
      </div>
    </>

  );
}

export default App;
