import React, {useState} from 'react'
import {toast } from 'react-toastify';
import { collection, addDoc } from "firebase/firestore"; 
import db  from '../../firebase';
import DeleteAccountConstants from '../../constants/DeleteAccountConstants';
import DeleteAccountForm from './components/DeleteAccountForm';
import AccountRequestSentCard from './components/AccountRequestSentCard';



export default function DeleteFormPage() {
  const [deletingAccount, setDeletingAccount] = useState(false);
  const [accountRequestSent, setAccountRequestSent] = useState(false);

  /// Receives JSON data from the form and sends it to the API to delete the account
  async function onSubmitForm(formData){

    setDeletingAccount(true);
    try {

      await addDoc(collection(db, DeleteAccountConstants.deleteAccountCollection), formData);

      toast.success('Your account delete request has been sent successfully')
      setAccountRequestSent(true);
    } catch (error) {
      toast.error('Something has gone wrong - please contact support through app@evc.co.uk')
    }
    setDeletingAccount(false);

  }



  return (
    <>
    <div className='flex flex-col items-center justify-center h-screen bg-center bg-no-repeat bg-cover place-items-center bg-journey-image '>

      <div className='flex flex-col w-10/12 h-auto bg-white rounded-xl'>
      <h1 className='w-full my-4 text-2xl font-bold text-center desktop:text-4xl'> {DeleteAccountConstants.deleteAccountPageTitle} </h1>
      {accountRequestSent
      ? <AccountRequestSentCard/> 
      : 
      <>
      <h4 className='w-auto mx-8 my-4 text-center text-s desktop:text-start desktop:text-lg'>{DeleteAccountConstants.deleteFormPageText} </h4>
      <p className='w-auto mx-8 my-4 text-xs text-center desktop:text-start desktop:text-s'>{DeleteAccountConstants.deleteFormStepsExplain}</p>
      <p className='w-auto mx-8 my-2 text-xs text-center underline desktop:text-start desktop:text-s'>{DeleteAccountConstants.thisActionCannotBeUndone}</p> 
      <DeleteAccountForm onSubmit={onSubmitForm} deletingAccount={deletingAccount} accountRequestSent={accountRequestSent}/>
      </>}
      <div className={`h-8`}/>
      </div>


    </div>
    </>
  )
}
