import React from 'react'
import LoadingSpinner from './LoadingSpinner'

export default function EVCButton({buttonText, buttonColor, buttonTextColor, onClick, loading}) {

  const onClickButton = () => {
    if(loading) return;

    onClick();
  }
  return (
    <div className='flex justify-center desktop:justify-end  desktop:mr-12 items-end w-auto '>      
        <button onClick={onClickButton} className={`${buttonColor} w-40 h-10 text-${buttonTextColor} text-xs py-2 px-8 rounded-full shadow-xl`}>
          {loading ? <LoadingSpinner/> : buttonText}  
        </button>
    </div>
  )
}
