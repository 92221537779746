import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";




const firebaseConfigDev = {
    apiKey: "AIzaSyApbik-ISAjsPj24t-TymTk6Xgbi_lU9_w",
    authDomain: "dev-evc-app-4d345.firebaseapp.com",
    projectId: "dev-evc-app-4d345",
    storageBucket: "dev-evc-app-4d345.appspot.com",
    messagingSenderId: "1085369160176",
    appId: "1:1085369160176:web:5d76b6924803cd01c1cd35",
    measurementId: "G-6N5S0ML6DN"
};

const firebaseConfigProd = {
    apiKey: "AIzaSyAeH1y8G6AJiBeJ2J2rXtP8n_0YNfVEUoM",
    authDomain: "prod-evc-app.firebaseapp.com",
    projectId: "prod-evc-app",
    storageBucket: "prod-evc-app.appspot.com",
    messagingSenderId: "887555542671",
    appId: "1:887555542671:web:fa3a785613c306a0766afa",
    measurementId: "G-2J29EDWMEY"
};


let configToUse;

if (process.env.REACT_APP_ENV === 'development') {
    configToUse = firebaseConfigDev;
} else if (process.env.REACT_APP_ENV === 'production') {
    configToUse = firebaseConfigProd;
}


// Initialize Firebase

const app = initializeApp(firebaseConfigProd);
const db = getFirestore(app);


export default db 