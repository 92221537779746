import React from 'react'

export default function AccountRequestSentCard() {
  return (
    <div className='flex flex-col justify-center w-full text-center'>
        <div className='flex w-auto flex-row rounded-3xl shadow-[-4px_4px_20px_0_rgba(20,20,20,0.25)] px-4 desktop:px-8 py-2 mx-4 mt-12'>
            <p>Your account deletion request has been successfully sent</p>
        </div>
        <div className='h-12'/>
        <p onClick={() => window.close()} className='text-xs underline'>You can close this page now</p>
    </div>
  )
}
