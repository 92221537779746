const DELETE_ACCOUNT_PAGE_TITLE = 'EVC Plus account delete form';
const WE_ARE_SORRY = 'We are sorry to see you go';
const DELETE_FORM_PAGE_TEXT = 'Please enter your email address below to delete your account.';
const DELETE_FORM_STEPS_EXPLAIN ='By entering your email address, we will delete your EVC Plus account and all its associated data such as name, email, phone number and financial information.';
const THIS_ACTION_CANNOT_BE_UNDONE = 'This action cannot be undone.';
const DELETE_FORM_PAGE_EMAIL_PLACEHOLDER = 'name.example@mail.com';
const DELETE_FORM_PAGE_DELETE_BUTTON_TEXT = 'Delete account';
const DELETE_ACCOUNT_COLLECTION = 'user_delete_request';


export default class DeleteAccountConstants {
    static deleteAccountPageTitle = DELETE_ACCOUNT_PAGE_TITLE;
    static weAreSorry = WE_ARE_SORRY;
    static deleteFormPageText = DELETE_FORM_PAGE_TEXT;
    static deleteFormStepsExplain = DELETE_FORM_STEPS_EXPLAIN;
    static thisActionCannotBeUndone = THIS_ACTION_CANNOT_BE_UNDONE;
    static deleteFormPageEmailPlaceholder = DELETE_FORM_PAGE_EMAIL_PLACEHOLDER;
    static deleteFormPageDeleteButtonText = DELETE_FORM_PAGE_DELETE_BUTTON_TEXT;
    static deleteAccountCollection = DELETE_ACCOUNT_COLLECTION;
}